import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import GettingStartedLink from 'components/front-page-hero/getting-started-link';
import imageResizer from 'js/image-resizer';
import Link from 'components/link';
import debounce from 'lodash/debounce';

const FrontPageHero = ({
  backgroundImage,
  backgroundImageMobile,
  mainHeading,
  secondaryHeadingLeft,
  secondaryHeadingRight,
  gettingStartedLinks,
  gettingStartedReadMoreLink,
}) => {
  const isDesktopView = () => {
    return document.body.clientWidth >= 768;
  };

  const [imageWidth, setImageWidth] = useState(0);
  const [imageSource, setImageSource] = useState(
    backgroundImageMobile ? null : backgroundImage
  );

  useEffect(() => {
    if (backgroundImageMobile) {
      setImageSource(isDesktopView() ? backgroundImage : backgroundImageMobile);
      let isDesktop = isDesktopView();
      const handleResize = () => {
        if (isDesktopView() !== isDesktop) {
          setImageSource(
            isDesktopView() ? backgroundImage : backgroundImageMobile
          );
          setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
        }
        isDesktop = isDesktopView();
      };

      window.addEventListener('resize', debounce(handleResize, 200));
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    setImageWidth(imageResizer.calcImageWidth(window.screen.width, 100));
  }, []);

  return (
    <div className="front-page-hero">
      <div className="front-page-hero__image-with-heading">
        {imageSource && (
          <Image
            width={imageWidth}
            className="front-page-hero__image"
            {...imageSource}
            fluid={true}
          />
        )}
        {mainHeading && (
          <div className="front-page-hero__heading-container">
            <h1 className="front-page-hero__heading">{mainHeading}</h1>
          </div>
        )}
        <div className="front-page-hero__wave" />
      </div>
      <div className="front-page-hero__background">
        <div className="front-page-hero__content">
          <div className="front-page-hero__secondary-headings">
            {secondaryHeadingLeft && (
              <h2 className="front-page-hero__left-heading">
                {secondaryHeadingLeft}
              </h2>
            )}

            {secondaryHeadingRight && (
              <h3 className="front-page-hero__right-heading">
                {secondaryHeadingRight}
              </h3>
            )}
          </div>
          {gettingStartedReadMoreLink && (
            <Link
              className="front-page-hero__reed-more-link link_theme_getting-started"
              {...gettingStartedReadMoreLink}
            />
          )}
          {gettingStartedLinks && (
            <ul className="front-page-hero__getting-started">
              {gettingStartedLinks.map((gettingStartedLink, index) => (
                <GettingStartedLink key={index} {...gettingStartedLink} />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

FrontPageHero.propTypes = {
  backgroundImage: PropTypes.exact(Image.propTypes),
  backgroundImageMobile: PropTypes.exact(Image.propTypes),
  mainHeading: PropTypes.string,
  secondaryHeadingLeft: PropTypes.string,
  secondaryHeadingRight: PropTypes.string,
  gettingStartedLinks: PropTypes.arrayOf(
    PropTypes.exact(GettingStartedLink.propTypes)
  ),
  gettingStartedReadMoreLink: PropTypes.exact(Link.propTypes),
};

export default FrontPageHero;
