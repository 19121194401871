import React from 'react';
import PropTypes from 'prop-types';
import SocialLink from 'components/social-link';
import RichText from 'components/rich-text';
import Link from 'components/link';
import LinkButton from 'components/link-button';

const Footer = ({
  content1Html,
  content2Html,
  subscribeButton,
  socialLinks,
  copyrightText,
  cookiesLink,
}) => {
  return (
    <div className="footer">
      <div className="footer__wave" />
      <div className="footer__background-container">
        <div className="footer__content">
          {content1Html && (
            <RichText
              className="footer__text rich-text_in-footer"
              text={content1Html}
            />
          )}
          {content2Html && (
            <RichText
              className="footer__text rich-text_in-footer"
              text={content2Html}
            />
          )}
          <div className="footer__links">
            {subscribeButton && (
              <LinkButton
                {...subscribeButton}
                className="footer__subscribe-link"
                theme="subscribe"
              />
            )}
            {socialLinks && (
              <ul className="footer__social-link-list">
                {socialLinks.map((socialLink, index) => (
                  <li key={index}>
                    <SocialLink {...socialLink} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="footer__copyright-row">
          <div className="footer__copyright-row-content">
            {copyrightText && (
              <div className="footer__copyright-row-text">{copyrightText}</div>
            )}
            {cookiesLink && (
              <Link className="footer__copyright-row-link" {...cookiesLink} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  subscribeButton: PropTypes.exact(LinkButton.propTypes),
  content1Html: PropTypes.string,
  content2Html: PropTypes.string,
  socialLinks: PropTypes.arrayOf(PropTypes.exact(SocialLink.propTypes)),
  copyrightText: PropTypes.string,
  cookiesLink: PropTypes.exact(Link.propTypes),
};

export default Footer;
