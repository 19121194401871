import React from 'react';
import PropTypes from 'prop-types';

const EmbeddableFormBlock = ({ html }) => {
  function createHtml() {
    return { __html: html };
  }
  return (
    <div className="embeddable-form-block">
      <div className="embeddable-form-block__content">
        <div dangerouslySetInnerHTML={createHtml()} />
      </div>
    </div>
  );
};

EmbeddableFormBlock.propTypes = {
  html: PropTypes.string,
};

export default EmbeddableFormBlock;
