import React from 'react';
import PropTypes from 'prop-types';
import MenuLink from 'components/menu-link';
import LinkButton from 'components/link-button/link-button';
import SocialLink from 'components/social-link';
import useToggle from 'hooks/use-toggle';
import cn from 'classnames';
import Icon from 'components/icon/icon';

const MainMenu = ({
  firstColumnLinks,
  secondColumnLinks,
  thirdColumnHeading,
  thirdColumnLinks,
  subscribeLink,
  socialLinks,
}) => {
  const [menuIsOpened, toggleMenu] = useToggle(false);

  const onMenuButtonClick = React.useCallback(() => {
    toggleMenu();
  }, []);

  return (
    <nav className="main-menu">
      <div className="main-menu__body">
        {firstColumnLinks && (
          <ul className="main-menu__items">
            {firstColumnLinks.map((link, index) => (
              <li key={index} className="main-menu__item">
                <MenuLink {...link} className="main-menu__link" />
              </li>
            ))}
          </ul>
        )}
        {secondColumnLinks && (
          <ul className="main-menu__items">
            {secondColumnLinks.map((link, index) => (
              <li key={index} className="main-menu__item">
                <MenuLink {...link} className="main-menu__link" />
              </li>
            ))}
          </ul>
        )}
        {thirdColumnLinks && (
          <div className="main-menu__third-column">
            {thirdColumnHeading && (
              <h2
                className={cn(
                  'main-menu__heading',
                  menuIsOpened
                    ? 'main-menu__heading_open'
                    : 'main-menu__heading_closed'
                )}
                onClick={onMenuButtonClick}
              >
                {thirdColumnHeading}
                <Icon className="main-menu__heading-icon" name="arrow" />
              </h2>
            )}
            <ul
              aria-expanded={menuIsOpened}
              className="main-menu__items main-menu__items_expandable"
            >
              {thirdColumnLinks.map((link, index) => (
                <li key={index} className="main-menu__item">
                  <MenuLink
                    {...link}
                    className="main-menu__link menu-link_small"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="main-menu__footer">
        {subscribeLink && (
          <LinkButton
            {...subscribeLink}
            className="main-menu__subscribe-link"
            theme="subscribe"
          />
        )}
        {socialLinks && (
          <div className="main-menu-social">
            <ul className="main-menu-social__list">
              {socialLinks.map((socialLink, index) => (
                <li key={index} className="main-menu-social__item">
                  <SocialLink
                    {...socialLink}
                    className="main-menu-social__link"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

MainMenu.propTypes = {
  firstColumnLinks: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
  secondColumnLinks: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
  thirdColumnHeading: PropTypes.string,
  thirdColumnLinks: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
  socialLinks: PropTypes.arrayOf(PropTypes.exact(SocialLink.propTypes)),
  subscribeLink: PropTypes.exact(LinkButton.propTypes),
};

export default MainMenu;
