import replaceQueryParameters from '@creuna/utils/replace-query-parameters';

const _getUndefinedIfEmpty = value => {
  return !value || value === '' || value.length === 0 ? undefined : value;
};

const getFilterSelectedValues = options => {
  return options.filter(o => o.isSelected).map(o => o.value);
};

const createQuery = () => {
  return new Object();
};

const setQueryValue = (query, name, value) => {
  query[name] = _getUndefinedIfEmpty(value);
};

const setToQueryFilterOptions = (query, filter, currentOptions = null) => {
  if (!filter) {
    return;
  }
  setQueryValue(
    query,
    filter.name,
    getFilterSelectedValues(currentOptions || filter.options)
  );
};

const setToQueryTextInputValue = (query, textInput, currentValue = null) => {
  if (!textInput) {
    return;
  }
  setQueryValue(query, textInput.name, currentValue ?? textInput.value);
};

const updatePageQueryString = query => {
  var newUrl = replaceQueryParameters(window.location.toString(), query);
  window.history.replaceState({}, document.title, newUrl);
};

export default {
  createQuery,
  setQueryValue,
  getFilterSelectedValues,
  setToQueryFilterOptions,
  setToQueryTextInputValue,
  updatePageQueryString,
};
