import React from 'react';
import PropTypes from 'prop-types';
import Teaser from 'components/teaser/teaser';

const SearchResult = ({ teasers }) => {
  return (
    <div className="search-result">
      {teasers && (
        <ul className="search-result__items">
          {teasers.map((teaser, index) => (
            <li className="search-result__item" key={index}>
              <Teaser {...teaser} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SearchResult.propTypes = {
  teasers: PropTypes.arrayOf(PropTypes.exact(Teaser.propTypes)),
  totalCount: PropTypes.number,
};

SearchResult.empty = () => {
  return {
    teasers: [],
    totalCount: 0,
  };
};

export default SearchResult;
