import React from 'react';
import PropTypes from 'prop-types';
import Question from './question';

const Questionnaire = ({
  getResultButtonText,
  restartButtonText,
  questions,
  selectNoteText,
}) => {
  const [showResults, setShowResults] = React.useState(false);
  const [qIndex, setQIndex] = React.useState(1);
  const [questionsList, setQuestionsList] = React.useState([]);
  const [showValidation, setShowValidation] = React.useState(false);

  const setAllQuestionsUnselected = () => {
    const tmpArr = questions && questions.map(q => q);
    for (var i in tmpArr) {
      tmpArr[i].isSelected = false;
    }
    setQuestionsList(tmpArr);
  };

  const onGetResultClick = () => {
    const selectedAnswers = questionsList.filter(q => q.isSelected === true);
    const allAnswersSelected = questions.length === selectedAnswers.length;

    if (allAnswersSelected) {
      setShowResults(true);
      setShowValidation(false);
    } else {
      setShowValidation(true);
      setShowResults(false);
    }
  };

  const onRestartClick = () => {
    setShowResults(false);
    setQIndex(qIndex + 1);
    setShowValidation(false);
    setAllQuestionsUnselected();
  };

  const onQuestionChange = (index, isSelected) => {
    let tmpArr = questionsList.map(q => ({ ...q }));
    tmpArr[index] = {
      ...tmpArr[index],
      isSelected: isSelected,
    };

    setQuestionsList(tmpArr);
  };

  React.useEffect(() => {
    setAllQuestionsUnselected();
  }, []);

  return (
    <div className="questionnaire">
      {questionsList && (
        <ul className="questionnaire__questions">
          {questionsList.map((question, index) => (
            <li className="questionnaire__question" key={index + qIndex}>
              <Question
                questionNumber={index}
                showResults={showResults}
                showValidation={showValidation}
                {...question}
                selectNoteText={selectNoteText}
                onChange={onQuestionChange}
              />
            </li>
          ))}
        </ul>
      )}

      {showResults ? (
        <button className="questionnaire__button" onClick={onRestartClick}>
          {restartButtonText}
        </button>
      ) : (
        <button
          className="questionnaire__button"
          onClick={e => {
            e.preventDefault;
            onGetResultClick();
          }}
        >
          {getResultButtonText}
        </button>
      )}
    </div>
  );
};

Questionnaire.propTypes = {
  getResultButtonText: PropTypes.string.isRequired,
  restartButtonText: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.exact(Question.propTypes)),
  selectNoteText: PropTypes.string,
};

export default Questionnaire;
