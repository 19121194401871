import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';

const Contact = ({ image, name, title, phoneLink, mailLink }) => {
  return (
    <div className="contact">
      <div className="contact__media-content">
        {image && <img className="contact__image" {...image} />}
      </div>
      <div className="contact__main-content">
        {name && <h3 className="contact__name">{name}</h3>}
        {title && <div className="contact__title">{title}</div>}
        {phoneLink && (
          <div className="contact__phone">
            <Link
              {...phoneLink}
              className="contact__link"
              openInNewTab={false}
            />
          </div>
        )}
        {mailLink && (
          <div className="contact__mail">
            <Link
              {...mailLink}
              className="contact__link"
              openInNewTab={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Contact.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  name: PropTypes.string,
  title: PropTypes.string,
  phoneLink: PropTypes.exact(Link.propTypes),
  mailLink: PropTypes.exact(Link.propTypes),
};

export default Contact;
