import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import RichText from 'components/rich-text';

const AccordionBlock = ({ title, items }) => {
  return (
    <div className="accordion-block">
      <div className="accordion-block__container">
        {title && <h2 className="accordion-block__title">{title}</h2>}
        <AccordionComponent
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <AccordionItem key={index}>
                  <AccordionItemState>
                    {({ expanded }) => (
                      <Fragment>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="accordion-block__item-heading">
                              {item.name}
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {expanded && item.textHtml && (
                            <RichText
                              className="accordion-block__text rich-text_in-accordion-block"
                              text={item.textHtml}
                            />
                          )}
                        </AccordionItemPanel>
                      </Fragment>
                    )}
                  </AccordionItemState>
                </AccordionItem>
              );
            })}
        </AccordionComponent>
      </div>
    </div>
  );
};

AccordionBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      textHtml: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AccordionBlock;
