import React from 'react';
import PropTypes from 'prop-types';
import PagesListItem from './pages-list-item';
import TagFilter from 'components/tag-filter';
import api from '../../js/api-helper';
import formHelper from 'js/form-helper';
import replaceQueryParameters from '@creuna/utils/replace-query-parameters';
import Spinner from 'components/spinner';

const PagesList = ({ tagFilter, loadItemsEndpoint, errorText }) => {
  const buildDefaultQuery = () => {
    const query = formHelper.createQuery();
    if (tagFilter) {
      formHelper.setToQueryFilterOptions(query, tagFilter);
    }
    return query;
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [pages, setPages] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [query, setQuery] = React.useState(buildDefaultQuery());

  const loadPages = () => {
    setIsLoading(true);
    var loadUrl = replaceQueryParameters(loadItemsEndpoint, query);
    api
      .get(loadUrl)
      .then(response => {
        setIsLoading(false);
        if (response.listItems.length !== 0) {
          setPages(response.listItems);
        }
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  const handleTagFilterChange = e => {
    formHelper.setToQueryFilterOptions(query, tagFilter, e.options);
    setQuery(Object.assign({}, query));
  };

  React.useEffect(() => {
    formHelper.updatePageQueryString(query);
    loadPages();
  }, [query]);
  return (
    <div className="pages-list">
      {tagFilter && (
        <div className="pages-list__filter">
          <TagFilter
            {...tagFilter}
            onChange={handleTagFilterChange}
            multiselect={true}
          />
          {isLoading && <Spinner />}
        </div>
      )}
      {error ? (
        <div>{errorText}</div>
      ) : (
        pages && (
          <ul className="pages-list__items">
            {pages.map(listItem => (
              <li className="pages-list__item" key={listItem.id}>
                <PagesListItem {...listItem} />
              </li>
            ))}
          </ul>
        )
      )}
    </div>
  );
};

PagesList.propTypes = {
  tagFilter: PropTypes.exact(TagFilter.propTypes),
  listItems: PropTypes.arrayOf(PropTypes.exact(PagesListItem.propTypes)),
  errorText: PropTypes.string.isRequired,
  loadItemsEndpoint: PropTypes.string.isRequired,
};

export default PagesList;
