import React from 'react';
import PropTypes from 'prop-types';
import GettingStartedTeaser from 'components/getting-started-teaser';

const GettingStartedList = ({ teasers }) => {
  return (
    <div className="getting-started-list">
      <ul className="getting-started-list__items">
        {teasers.map((teaser, index) => (
          <li className="getting-started-list__item" key={index}>
            <GettingStartedTeaser {...teaser} />
          </li>
        ))}
      </ul>
    </div>
  );
};

GettingStartedList.propTypes = {
  teasers: PropTypes.arrayOf(PropTypes.exact(GettingStartedTeaser.propTypes))
    .isRequired,
};

export default GettingStartedList;
