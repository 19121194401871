import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Icon from 'components/icon/icon';

const Teaser = ({
  heading,
  image,
  tags,
  url,
  trackUrl,
  cookTime,
  difficulty,
}) => {
  let TeaserTag = url ? 'a' : 'div';
  let attributes = url ? { href: url } : {};

  const handleLinkClick = e => {
    if (trackUrl) {
      var defaultBehaviour = e.ctrlKey;
      if (!defaultBehaviour) {
        e.preventDefault();
      }
      fetch(trackUrl, {
        credentials: 'same-origin',
      }).then(_response => {
        if (!defaultBehaviour && url) {
          window.location.href = url;
        }
      });
    }
  };

  return (
    <TeaserTag
      className="teasers-list__teaser"
      {...attributes}
      onClick={handleLinkClick}
    >
      {image && <Image className="teasers-list__teaser-image" {...image} />}
      {tags && (
        <div className="teasers-list__teaser-tags">{tags.join(', ')}</div>
      )}
      {heading && <h3 className="teasers-list__teaser-heading">{heading}</h3>}
      {(cookTime || difficulty) && (
        <div className="teasers-list__teaser-details">
          {cookTime && (
            <div className="teasers-list__teaser-detail">
              <Icon name="clock" />
              <span className="teasers-list__teaser-detail-text">
                {cookTime}
              </span>
            </div>
          )}
          {difficulty && (
            <div className="teasers-list__teaser-detail">
              <Icon name="cook" />
              <span className="teasers-list__teaser-detail-text">
                {difficulty}
              </span>
            </div>
          )}
        </div>
      )}
    </TeaserTag>
  );
};

Teaser.propTypes = {
  heading: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  tags: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
  trackUrl: PropTypes.string,
  cookTime: PropTypes.string,
  difficulty: PropTypes.string,
};
export default Teaser;
