import React from 'react';
import PropTypes from 'prop-types';
import MenuLink from 'components/menu-link';

const HeaderMenu = ({ headerMenuLinks }) => {
  return (
    <div className="header-menu">
      {headerMenuLinks && (
        <ul className="header-menu__items">
          {headerMenuLinks.map((link, index) => (
            <li key={index} className="header-menu__item">
              <MenuLink {...link} className="header-menu__link menu-link_big" />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

HeaderMenu.propTypes = {
  headerMenuLinks: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
};

export default HeaderMenu;
