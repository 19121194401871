import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SearchForm from './search-form';
import SearchResult from 'components/search-result';
import api from '../../js/api-helper';
import replaceQueryParameters from '@creuna/utils/replace-query-parameters';

import stringHelper from 'js/string-helper';
import Spinner from 'components/spinner';
import formHelper from 'js/form-helper';

const Search = ({
  headingTemplate,
  heading,
  searchForm,
  searchEndpoint,
  pageSize,
  errorText,
  noResultsText,
  loadMoreText,
  loadingText,
}) => {
  const [result, setResult] = useState(SearchResult.empty());
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [currentHeading, setCurrentHeading] = useState(null);
  const [page, setPage] = useState(1);

  const updateHeading = searchText => {
    if (searchText) {
      if (headingTemplate) {
        var newHeading = stringHelper.format(headingTemplate, searchText);
        setCurrentHeading(newHeading);
      }
    } else {
      setCurrentHeading(heading);
    }
  };

  const search = () => {
    const currentResult = result;
    setIsLoading(true);
    setShowError(false);
    let query = Object.assign({}, searchQuery);
    formHelper.updatePageQueryString(query);
    query = Object.assign(query, { page: page, pageSize: pageSize });
    var searchUrl = replaceQueryParameters(searchEndpoint, query);
    api
      .execute(searchUrl)
      .then(response => {
        setIsLoading(false);
        const loadedResult = response;
        loadedResult.teasers = [
          ...currentResult.teasers,
          ...loadedResult.teasers,
        ];
        setResult(loadedResult);
      })
      .catch(() => {
        setShowError(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (searchQuery) {
      search();
    }
  }, [searchQuery, page]);

  const handleFormChange = e => {
    setSearchQuery(Object.assign({}, e.query));
    updateHeading(e.searchText);
    setPage(1);
    setResult(SearchResult.empty());
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  return (
    <div className="search">
      {currentHeading && <h1 className="search__title">{currentHeading}</h1>}
      <div className="search__form">
        <SearchForm
          {...searchForm}
          onChange={handleFormChange}
          onInit={handleFormChange}
          isLoading={isLoading}
        />

        {isLoading && <Spinner text={loadingText} />}
      </div>
      {showError && errorText && <div>{errorText}</div>}
      {result.teasers.length > 0 ? (
        <SearchResult {...result} />
      ) : (
        !isLoading && noResultsText && <div>{noResultsText}</div>
      )}
      {result.teasers.length < result.totalCount && (
        <div className="search-result__button-container">
          <button className="search-result__button" onClick={handleLoadMore}>
            {loadMoreText}
          </button>
          {isLoading && <Spinner text={loadingText} />}
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  headingTemplate: PropTypes.string,
  heading: PropTypes.string,
  searchForm: PropTypes.exact(SearchForm.propTypes).isRequired,
  noResultsText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  searchEndpoint: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  loadMoreText: PropTypes.string.isRequired,
  loadingText: PropTypes.string.isRequired,
};

export default Search;
