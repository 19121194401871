import React from 'react';
import PropTypes from 'prop-types';
import SelectOption from 'components/select-option';
import Icon from 'components/icon';
import useToggle from '../../hooks/use-toggle';
import useEvent from '../../hooks/use-event';
import useClickOutside from '../../hooks/use-click-outside';
import useIsMounted from '../../hooks/use-is-mounted';
import cn from 'classnames';

const CategoryFilter = ({
  label,
  placeholder,
  options = [],
  name,
  onChange = () => {},
}) => {
  const [isOpen, toggle, close] = useToggle(false);
  const [hasTouch, setHasTouch] = React.useState(false);
  useEvent('touchstart', () => setHasTouch(true));

  const fakeSelectRef = React.useRef();
  useClickOutside(fakeSelectRef, close);

  const [selectedOption, setSelectedOption] = React.useState(
    options.find(o => o.isSelected) || {}
  );

  const isMounted = useIsMounted();

  const handleChange = value => {
    setSelectedOption(options.find(x => x.value === value) || {});
    const currentOptions = options.map(option => {
      const currentOption = Object.assign({}, option);
      currentOption.isSelected = currentOption.value === value;
      return currentOption;
    });
    onChange({ options: currentOptions });
    close();
  };

  return (
    <div
      className={cn('category-filter', {
        'category-filter--active': isOpen,
        'category-filter--has-touch': hasTouch,
        'category-filter--is-mounted': isMounted,
      })}
    >
      {options && (
        <div>
          <select
            className="category-filter__select"
            name={name}
            onChange={e => handleChange(e.target.value)}
            value={selectedOption.value}
            aria-label={label}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="category-filter__fake">
            <div
              aria-hidden="true"
              className="category-filter__element"
              onClick={toggle}
              ref={fakeSelectRef}
            >
              {selectedOption.label || placeholder}
              <Icon
                className={cn('category-filter__icon', {
                  'category-filter__icon--active': isOpen,
                })}
                name="arrow"
              />
            </div>
            {isOpen && (
              <ul className="category-filter__dropdown">
                {placeholder && (
                  <li
                    aria-hidden="true"
                    className="category-filter__option"
                    onClick={() => handleChange('')}
                  >
                    {placeholder}
                  </li>
                )}
                {options
                  .filter(option => option !== selectedOption)
                  .map(option => (
                    <li
                      aria-hidden="true"
                      className="category-filter__option"
                      key={option.value}
                      onClick={() => handleChange(option.value)}
                    >
                      <span>{option.label}</span>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

CategoryFilter.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.exact(SelectOption.propTypes)),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default CategoryFilter;
