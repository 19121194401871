import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/use-toggle';
import useClickOutside from 'hooks/use-click-outside';
import MainMenu from 'components/main-menu';
import HeaderMenu from 'components/header/header-menu';
import QuickSearchPanel from 'components/quick-search-panel';
import cn from 'classnames';
import Icon from 'components/icon';
import Image from 'components/image';
import useEvent from 'hooks/use-event';
import FocusTrap from 'focus-trap-react';

const Header = ({
  logo,
  mainMenu,
  quickSearchPanel,
  headerMenu,
  buttonSearchAriaLabel = 'Søkevindu',
  buttonMenuAriaLabel = 'Meny',
  buttonCloseAriaLabel,
  quickSearchPanelTitleAriaLabel = 'Modalvindu for globalsøk',
  mainMenuTitleAriaLabel = 'Modalvindu for hovedmeny',
}) => {
  const [popupIsOpened, togglePopup, hidePopup] = useToggle(false);
  const [searchMode, setSearchModIsActive] = useState(false);

  const onMenuButtonClick = useCallback(() => {
    setSearchModIsActive(false);
    togglePopup();
  }, []);

  const onSearchButtonClick = React.useCallback(() => {
    setSearchModIsActive(true);
    togglePopup();
  }, []);

  const navigationRef = React.useRef();

  useClickOutside(navigationRef, () => {
    hidePopup();
  });

  useEvent('keydown', event => {
    event.key === 'Escape' && hidePopup();
  });

  return (
    <div className="header">
      <a href={logo.url} className="header__logo" aria-label={logo.ariaLabel}>
        {logo.image && <Image {...logo.image} />}
      </a>
      <HeaderMenu {...headerMenu} />
      <div className="header__toolbar" ref={navigationRef}>
        <button
          className="header__button"
          aria-label={buttonSearchAriaLabel}
          onClick={onSearchButtonClick}
          aria-haspopup="true"
        >
          <Icon name="search" />
        </button>
        <button
          className="header__button"
          aria-label={buttonMenuAriaLabel}
          onClick={onMenuButtonClick}
          aria-haspopup="true"
        >
          <Icon name="menu" />
        </button>
        <FocusTrap active={popupIsOpened}>
          <div
            className={cn('header__popup', {
              header__popup_opened: popupIsOpened,
            })}
            role="dialog"
            {...(popupIsOpened && {
              'aria-modal': 'true',
            })}
            aria-label={
              searchMode
                ? quickSearchPanelTitleAriaLabel
                : mainMenuTitleAriaLabel
            }
          >
            <div className="header__popup-header">
              <a
                href={logo.url}
                className="header__popup-logo"
                aria-label={logo.ariaLabel}
              >
                {logo.expandedMenuImage && (
                  <Image {...logo.expandedMenuImage} />
                )}
              </a>
              <button
                className="header__button"
                aria-label={buttonCloseAriaLabel}
                onClick={onMenuButtonClick}
              >
                <Icon name="close" />
              </button>
            </div>
            {searchMode ? (
              <QuickSearchPanel hidePopup={hidePopup} {...quickSearchPanel} />
            ) : (
              <MainMenu {...mainMenu} logo={logo} />
            )}
          </div>
        </FocusTrap>
      </div>
    </div>
  );
};

Header.propTypes = {
  logo: PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.exact(Image.propTypes),
    expandedMenuImage: PropTypes.exact(Image.propTypes),
    ariaLabel: PropTypes.string,
  }),
  mainMenu: PropTypes.exact(MainMenu.propTypes),
  headerMenu: PropTypes.exact(HeaderMenu.propTypes),
  quickSearchPanel: PropTypes.exact(QuickSearchPanel.propTypes),
  buttonSearchAriaLabel: PropTypes.string,
  buttonMenuAriaLabel: PropTypes.string,
  buttonCloseAriaLabel: PropTypes.string,
  quickSearchPanelTitleAriaLabel: PropTypes.string,
  mainMenuTitleAriaLabel: PropTypes.string,
};

export default Header;
