/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'components/link';

const MenuLink = ({
  className,
  openInNewTab,
  url,
  text,
  tabindex,
  isActive,
  isCurrent = false,
}) => (
  <Link
    url={url}
    className={cn('menu-link', {
      [className]: className,
      [`${className}_active`]: isActive,
    })}
    openInNewTab={openInNewTab}
    tabIndex={tabindex}
    ariaCurrent={isCurrent ? 'page' : null}
    text={text}
  />
);

MenuLink.propTypes = Object.assign({}, Link.propTypes, {
  isActive: PropTypes.bool,
  isCurrent: PropTypes.bool,
});

export default MenuLink;
