import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CoursePartLink from './course-part-link';
import Spinner from 'components/spinner';
import scrollToElement from 'scroll-to-element';

const CourseContent = ({
  coursePartsLinks,
  loadCoursePartEndpoint,
  loadingText,
  errorText,
  prevButtonText,
  nextButtonText,
  navigationAriaLabel = 'Navigasjonsmeny for undervisning',
}) => {
  const numberOfPartsLinks = coursePartsLinks ? coursePartsLinks.length : 0;
  const partsLinksAreExist = numberOfPartsLinks > 0;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageContent, setPageContent] = useState(null);
  const [pageId, setPageId] = useState(
    partsLinksAreExist ? coursePartsLinks[0].partId : null
  );

  const scrollToTopOfContent = () => {
    scrollToElement('.course-content', {
      offset: 0,
      ease: 'linear',
      duration: 500,
    });
  };

  const firstPartsLinkIsActive = partsLinksAreExist
    ? pageId !== coursePartsLinks[0].partId
    : false;

  const lastPartsLinkIsActive = partsLinksAreExist
    ? pageId !== coursePartsLinks[numberOfPartsLinks - 1].partId
    : false;

  const currentPartsLinkIndex = partsLinksAreExist
    ? coursePartsLinks.findIndex(p => p.partId === pageId)
    : null;

  const loadPageContent = () => {
    setIsLoading(true);
    setError(null);

    let xhr = new XMLHttpRequest();
    let query = `${loadCoursePartEndpoint}?partId=${pageId}`;

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          setIsLoading(false);
          setPageContent(xhr.response);
        }
      }
    };

    xhr.onerror = function () {
      setError(errorText);
      setIsLoading(false);
    };

    xhr.open('GET', query, true);
    xhr.send();
  };

  const handleNavigate = activePartId => {
    setPageId(activePartId);
  };

  const handlePrevButtonClick = () => {
    firstPartsLinkIsActive &&
      setPageId(coursePartsLinks[currentPartsLinkIndex - 1].partId);
    scrollToTopOfContent();
  };

  const handleNextButtonClick = () => {
    lastPartsLinkIsActive &&
      setPageId(coursePartsLinks[currentPartsLinkIndex + 1].partId);
    scrollToTopOfContent();
  };

  useEffect(() => {
    pageId && loadPageContent(pageId);
  }, [pageId]);

  return (
    <div className="course-content">
      {coursePartsLinks && (
        <nav
          className="course-content__navigation"
          aria-label={navigationAriaLabel}
        >
          <ul className="course-content__navigation-items">
            {coursePartsLinks.map((link, index) => (
              <li className="course-content__navigation-item" key={index}>
                <CoursePartLink
                  {...link}
                  active={pageId === link.partId}
                  onClick={e => handleNavigate(e.partId)}
                />
              </li>
            ))}
          </ul>
          {isLoading && <Spinner text={loadingText} />}
        </nav>
      )}

      {error && errorText && (
        <div className="course-content__error">{error}</div>
      )}
      {pageContent && <div dangerouslySetInnerHTML={{ __html: pageContent }} />}

      {numberOfPartsLinks > 0 && (
        <div className="course-content__navigation-buttons">
          {firstPartsLinkIsActive && (
            <button
              onClick={handlePrevButtonClick}
              className="course-content__navigation-button course-content__navigation-button--prev"
            >
              {prevButtonText}
            </button>
          )}
          {lastPartsLinkIsActive && (
            <button
              onClick={handleNextButtonClick}
              className="course-content__navigation-button course-content__navigation-button--next"
            >
              {nextButtonText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

CourseContent.propTypes = {
  coursePartsLinks: PropTypes.arrayOf(
    PropTypes.exact(CoursePartLink.propTypes)
  ),
  loadCoursePartEndpoint: PropTypes.string.isRequired,
  loadingText: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  nextButtonText: PropTypes.string.isRequired,
  prevButtonText: PropTypes.string.isRequired,
  navigationAriaLabel: PropTypes.string,
};

export default CourseContent;
