import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon/icon';

const RecipePageDetails = ({ cookTime, difficulty }) => {
  return (
    <div className="recipe-page__details">
      {cookTime && (
        <div className="recipe-page__detail">
          <Icon name="clock-big" />
          <span className="recipe-page__detail-text">{cookTime}</span>
        </div>
      )}
      {difficulty && (
        <div className="recipe-page__detail">
          <Icon name="cook-big" />
          <span className="recipe-page__detail-text">{difficulty}</span>
        </div>
      )}
    </div>
  );
};

RecipePageDetails.propTypes = {
  cookTime: PropTypes.string,
  difficulty: PropTypes.string,
};

export default RecipePageDetails;
