import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CategoryFilter from './category-filter';
import SearchQueryInput from './search-query-input';
import TagFilter from 'components/tag-filter';
import formHelper from 'js/form-helper';

const SearchForm = ({
  categoryFilter1,
  categoryFilter2,
  searchQueryInput,
  tagFilter,
  formAriaLabel = 'Søkefelt for globalsøk',
  onChange = () => {},
  onInit = () => {},
}) => {
  const [query, setQuery] = React.useState(null);

  const buildInitQuery = () => {
    var query = formHelper.createQuery();
    formHelper.setToQueryFilterOptions(query, categoryFilter1);
    formHelper.setToQueryFilterOptions(query, categoryFilter2);
    formHelper.setToQueryFilterOptions(query, tagFilter);
    formHelper.setToQueryTextInputValue(query, searchQueryInput);
    return query;
  };

  useEffect(() => {
    var initQuery = buildInitQuery();
    setQuery(initQuery);
    onInit({ query: initQuery, searchText: initQuery[searchQueryInput.name] });
  }, []);

  const queryUpdated = () => {
    setQuery(Object.assign({}, query));
    onChange({ query: query, searchText: query[searchQueryInput.name] });
  };

  const handleFilterChange = (filter, e) => {
    formHelper.setToQueryFilterOptions(query, filter, e.options);
    queryUpdated();
  };

  const handleTextInputChnage = (input, e) => {
    formHelper.setToQueryTextInputValue(query, input, e.value);
    queryUpdated();
  };

  return (
    <form
      method="GET"
      className="search-form"
      role="search"
      aria-label={formAriaLabel}
    >
      <div className="search-form__filters-container">
        {categoryFilter1 && (
          <div className="search-form__column">
            <CategoryFilter
              onChange={e => handleFilterChange(categoryFilter1, e)}
              {...categoryFilter1}
            />
          </div>
        )}
        {categoryFilter2 && (
          <div className="search-form__column">
            <CategoryFilter
              onChange={e => handleFilterChange(categoryFilter2, e)}
              {...categoryFilter2}
            />
          </div>
        )}
        <div className="search-form__column">
          <SearchQueryInput
            onChange={e => handleTextInputChnage(searchQueryInput, e)}
            {...searchQueryInput}
          />
        </div>
      </div>
      {tagFilter && (
        <TagFilter
          onChange={e => handleFilterChange(tagFilter, e)}
          {...tagFilter}
        />
      )}
    </form>
  );
};

SearchForm.propTypes = {
  categoryFilter1: PropTypes.exact(CategoryFilter.propTypes),
  categoryFilter2: PropTypes.exact(CategoryFilter.propTypes),
  searchQueryInput: PropTypes.exact(SearchQueryInput.propTypes).isRequired,
  tagFilter: PropTypes.exact(TagFilter.propTypes),
  formAriaLabel: PropTypes.string,
  onChange: PropTypes.func,
  onInit: PropTypes.func,
};

export default SearchForm;
