import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';

const ContactInformation = ({ heading, textHtml }) => {
  return (
    <div className="contact-information">
      {heading && <h2 className="contact-information__heading">{heading}</h2>}
      {textHtml && (
        <RichText
          className="rich-text_in-contact-information"
          text={textHtml}
        />
      )}
    </div>
  );
};

ContactInformation.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
};

export default ContactInformation;
