import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/link-button';

const SeeAlsoLinks = ({ heading, links }) => {
  return (
    <div className="see-also-links">
      {heading && <h2>{heading}</h2>}
      {links && (
        <ul className="see-also-links__items">
          {links.map((link, index) => (
            <li className="see-also-links__item" key={index}>
              <LinkButton
                className="see-also-links__link"
                iconName="arrow"
                {...link}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SeeAlsoLinks.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(LinkButton.propTypes)),
};

export default SeeAlsoLinks;
