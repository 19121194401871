import React from 'react';
import PropTypes from 'prop-types';
import Teaser from 'components/teaser/teaser';
import LinkButton from 'components/link-button/link-button';

const TeasersList = ({ heading, teasers, seeMoreLink }) => {
  return (
    <div className="teasers-list">
      {heading && <h2 className="teasers-list__heading">{heading}</h2>}
      <ul className="teasers-list__items">
        {teasers.map((teaser, index) => (
          <li key={index} className="teasers-list__item">
            <Teaser {...teaser} />
          </li>
        ))}
      </ul>
      {seeMoreLink && (
        <LinkButton
          className="teasers-list__more-link"
          iconName="arrow"
          {...seeMoreLink}
        />
      )}
    </div>
  );
};

TeasersList.propTypes = {
  heading: PropTypes.string,
  teasers: PropTypes.arrayOf(PropTypes.exact(Teaser.propTypes)).isRequired,
  seeMoreLink: PropTypes.exact(LinkButton.propTypes),
};
export default TeasersList;
