import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import QuickSearchPanel from 'components/quick-search-panel';
import LinkButton from 'components/link-button';

const ErrorBlock = ({ errorCode, bigText, smallText, image, search, link }) => {
  return (
    <div className="error-block">
      <h1 className="error-block__title">{errorCode}</h1>
      <div className="error-block__big-text">{bigText}</div>
      <div className="error-block__small-text">{smallText}</div>
      {image && (
        <div className="error-block__image-container">
          <Image {...image}></Image>
        </div>
      )}
      {search && (
        <QuickSearchPanel
          theme={QuickSearchPanel.themes.blueWithIcon}
          {...search}
        />
      )}
      {link && (
        <div className="error-block__link-container">
          <LinkButton className="error-block__link" {...link} />
        </div>
      )}
    </div>
  );
};

ErrorBlock.propTypes = {
  errorCode: PropTypes.string,
  bigText: PropTypes.string,
  smallText: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  search: PropTypes.exact(QuickSearchPanel.propTypes),
  link: PropTypes.exact(LinkButton.propTypes),
};

export default ErrorBlock;
