import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';

const SearchQueryInput = ({
  label,
  name,
  value,
  placeholder,
  autocompleteEndpoint, // TODO: BE side done, FE side is not implemented yet.
  submitButtonAriaLabel = 'Sende inn',
  onChange = () => {},
}) => {
  const [currentValue, setCurrentValue] = React.useState(value);

  const handleInput = e => {
    setCurrentValue(e.target.value);
  };

  const handleClick = e => {
    e.preventDefault();
    onChange({ value: currentValue });
    return false;
  };

  return (
    <div className="search-query-input">
      <input
        className="search-query-input__input"
        type="text"
        name={name}
        value={currentValue}
        placeholder={placeholder}
        onChange={handleInput}
        aria-label={label}
      ></input>
      <button
        className="search-query-input__icon-submit-button"
        aria-label={submitButtonAriaLabel}
        onClick={handleClick}
      >
        <Icon className="search-query-input__search-icon" name="search" />
      </button>
    </div>
  );
};

SearchQueryInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  autocompleteEndpoint: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  submitButtonAriaLabel: PropTypes.string,
};

export default SearchQueryInput;
