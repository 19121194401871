import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import LinkButton from 'components/link-button';
import cn from 'classnames';

const styles = {
  default: 'default',
  overlay: 'overlay',
};

const FullWidthBanner = ({
  backgroundImage,
  heading,
  textHtml,
  style,
  readMoreButton,
}) => {
  return (
    <div
      className={cn('full-width-banner', {
        [`full-width-banner_style_${style}`]: style,
      })}
    >
      {backgroundImage && (
        <Image
          {...backgroundImage}
          className="full-width-banner__image image_size_full-width-banner"
        />
      )}
      <div className="full-width-banner__content">
        {heading && <h2 className="full-width-banner__heading">{heading}</h2>}
        {textHtml && (
          <RichText
            className="full-width-banner__text rich-text_in-full-width-banner"
            text={textHtml}
          />
        )}
        {readMoreButton && (
          <LinkButton
            className="full-width-banner__more-link"
            {...readMoreButton}
          />
        )}
      </div>
    </div>
  );
};

FullWidthBanner.propTypes = {
  backgroundImage: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  style: PropTypes.oneOf(Object.values(styles)).isRequired,
  readMoreButton: PropTypes.exact(LinkButton.propTypes),
};

export default FullWidthBanner;
