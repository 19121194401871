import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import useToggle from 'hooks/use-toggle';
import Image from 'components/image';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import YouTube from 'react-youtube';
import cn from 'classnames';
import useEvent from 'hooks/use-event';

const Video = ({
  logo,
  thumbnailImage,
  closeLabel,
  videoId,
  sources,
  autoplay,
  showRelated,
  modalTitleAriaLabel = 'Modalvindu for videoavspiller',
}) => {
  const [popupIsOpened, togglePopup, hidePopup] = useToggle(false);
  const [youtubePlayerReady, setYoutubePlayerReady] = useState(false);
  const videoBlock = useRef();

  useEvent('keydown', event => {
    if (event.key === 'Escape') {
      hidePopup();
    }
  });

  const youtubeVideoOptions = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: autoplay ? 1 : 0,
      rel: showRelated ? 1 : 0,
    },
  };

  const localVideoOptions = {
    ...(autoplay ? { autoPlay: 'autoplay' } : {}),
  };

  const onReady = useCallback(() => {
    setYoutubePlayerReady(true);
    if (videoBlock && videoBlock.current) {
      const youtubeIframe = videoBlock.current.querySelector('iframe');

      if (youtubeIframe) {
        youtubeIframe.setAttribute('tabindex', '0');
      }
    }
  }, [videoBlock]);

  return (
    <div className="video" ref={videoBlock}>
      <button
        className="video__overlay"
        onClick={togglePopup}
        aria-haspopup="true"
      >
        <Icon name="play"></Icon>
      </button>
      {thumbnailImage && thumbnailImage.src && (
        <Image {...thumbnailImage} className="video__image" />
      )}
      <Modal
        closeLabel={closeLabel}
        onClose={hidePopup}
        isOpened={popupIsOpened}
        logo={logo}
        titleAriaLabel={modalTitleAriaLabel}
      >
        {sources && sources.length > 0 ? (
          <div className="local-video">
            <video
              poster={
                thumbnailImage && thumbnailImage.src ? thumbnailImage.src : ''
              }
              controls={true}
              loop={false}
              muted={false}
              {...localVideoOptions}
            >
              {sources.map((source, index) => (
                <source key={index} src={source.url} type={source.mimeType} />
              ))}
            </video>
          </div>
        ) : (
          videoId && (
            <div className="video__iframe-container">
              {!youtubePlayerReady && <Spinner />}
              <YouTube
                videoId={videoId}
                opts={youtubeVideoOptions}
                onReady={onReady}
                containerClassName={cn('video__iframe-holder', {
                  'video__iframe-holder_visible': youtubePlayerReady,
                })}
              />
            </div>
          )
        )}
      </Modal>
    </div>
  );
};

Video.propTypes = {
  logo: PropTypes.exact(Image.propTypes),
  closeLabel: PropTypes.string,
  thumbnailImage: PropTypes.exact(Image.propTypes),
  videoId: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
    })
  ),
  autoplay: PropTypes.bool,
  showRelated: PropTypes.bool,
  modalTitleAriaLabel: PropTypes.string,
};

export default Video;
