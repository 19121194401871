import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';

const Breadcrumbs = ({ links, ariaLabel = 'Brødsmulesti', className }) => {
  return links ? (
    <nav
      aria-label={ariaLabel}
      className={cn('breadcrumbs', {
        [className]: className,
      })}
    >
      <ul className="breadcrumbs__items">
        {links.map((item, index) => {
          return (
            <li key={index} className="breadcrumbs__item">
              <Link
                className="breadcrumbs__item-link"
                ariaCurrent={index === links.length - 1 ? 'page' : null}
                {...item}
              />
              {index !== links.length - 1 && <span>&nbsp;/&nbsp;</span>}
            </li>
          );
        })}
      </ul>
    </nav>
  ) : null;
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default Breadcrumbs;
