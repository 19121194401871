import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import RichText from 'components/rich-text';
import idHelper from 'js/id-helper';

const GettingStartedLink = ({ heading, introHtml, link }) => {
  const uniqueId = useMemo(
    () => idHelper.generateUniqueId('getting-started-link'),
    []
  );
  const headingId = `${uniqueId}-heading`;
  const descriptionId = `${uniqueId}-description`;

  return (
    <li className="front-page-hero__getting-started-item">
      {heading && (
        <h3
          id={headingId}
          className="front-page-hero__getting-started-item-heading"
        >
          {heading}
        </h3>
      )}
      {introHtml && (
        <RichText
          id={descriptionId}
          className="front-page-hero__getting-started-item-intro rich-text_in-getting-started-link-intro"
          text={introHtml}
        />
      )}
      {link && (
        <Link
          className="front-page-hero__getting-started-item-link link_theme_getting-started"
          ariaLabelledBy={headingId}
          ariaDescribedBy={descriptionId}
          {...link}
        />
      )}
    </li>
  );
};

GettingStartedLink.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
};

export default GettingStartedLink;
