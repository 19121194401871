import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';
import isRunningOnClient from '@creuna/utils/is-running-on-client';

const PrintButton = ({ label, className }) => {
  const printPage = () => {
    isRunningOnClient && window.print();
  };

  return (
    <button
      onClick={printPage}
      className={cn('print-button', {
        [className]: className,
      })}
    >
      <Icon className="print-button__icon" name="print" />
      {label && <span className="print-button__text">{label}</span>}
    </button>
  );
};

PrintButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default PrintButton;
