import React from 'react';
import PropTypes from 'prop-types';
import SimpleListTeaser from './simple-list-teaser';

const SimpleList = ({ topTeaser, teasers }) => {
  return (
    <div className="simple-list">
      {topTeaser && <SimpleListTeaser size="default" {...topTeaser} />}
      <ul className="simple-list__items">
        {teasers.map((teaser, index) => (
          <li className="simple-list__item" key={index}>
            <SimpleListTeaser size="small" {...teaser} />
          </li>
        ))}
      </ul>
    </div>
  );
};

SimpleList.propTypes = {
  topTeaser: PropTypes.exact(SimpleListTeaser.propTypes),
  teasers: PropTypes.arrayOf(PropTypes.exact(SimpleListTeaser.propTypes)),
};

export default SimpleList;
